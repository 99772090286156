import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Heading4 from '../../components/Type/Heading4'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({ location }) =>
(
  <Layout>

    <SEO title="Porsche 911 Carrera GTS buyers guide"
      description="Buying a Porsche 911 Carrera GTS? Carrera GTS prices, specs and how to buy a good one"
      location={location}
    />

    <PageHeader
      title="Porsche 911 Carrera GTS buyers guide"
      text="The shortage of new car supplies caused by Covid has bolstered used car prices generally, but values of the 991-series Porsche 911 Carrera GTS haven’t merely been strong, they’ve increased during the past eight months."
    />

    <SectionPageMargin>

      <Heading2>Buying a Porsche 911 Carrera GTS? Carrera GTS prices, specs and how to buy a good one</Heading2>

      <Paragraph1>Last update: 28th September 2021</Paragraph1>

      <Heading4>Market Analysis</Heading4>

      <Paragraph1>
        Why are prices increasing? Rarity plays a part here, at least in terms of market availability. At any one time there can be as few as three 991.2 GTS models actively advertised in Australia – it’s the same story for the earlier, first generation 991.1 GTS derivatives. If you have a preference for colour or body style – Coupe, Cabriolet, Targa – then you may have a long wait to find the right car, and you’re not likely to be alone in wanting to buy it. Demand and supply – in this instance it doesn’t favour the bargain hunter…
      </Paragraph1>

      <Paragraph1>
        In the six-month period from November 2021 to May 2021, the average price of a 991.1 Carrera GTS rose by about 6% compared with the previous six months. During the same timeframe, prices of the 991.2 Carrera GTS increased by about 5%. Average mileage of the 991.1 models increased by 9,000km (to 32,000km) whilst 991.2’s command a higher price and being newer have lower mileage (averaging 16,000km).
      </Paragraph1>

      <Paragraph1>
        To put the Carrera GTS’s extraordinary performance on the used car market into perspective, the next model down on the 991 food chain, the Carrera S, has remained static using the same reference markers. Even that achievement is remarkable and demonstrates the might of Porsche’s reputation as the maker of high performance, well-built sports cars.
      </Paragraph1>

      <Paragraph1>
        Given the scarcity of cars for sale, it’s fortunate that the 991 Carrera GTS is blessed with a very high spec – both in terms of interior appointments and technical features – as standard: it means that at least you don’t have to worry about waiting to find one with desirable kit.
      </Paragraph1>

      <Paragraph1>
        The caveats here are if you especially want a manual gearbox, as the excellent PDK automatically operated dual-clutch manual is far more prevalent, or if you’ve set your heart on the Porsche Carbon Ceramic Brakes (PCCB) – they were a $20K option when new so not too many owners would have ticked that box when ordering their car.
      </Paragraph1>

      <Paragraph1>
        The 991 Carrera GTS is regarded as the pinnacle of the ‘standard’ non-GT 911 models – GT3, GT3 RS, Turbo, Turbo S – which explains its desirability. Even so, the increase in its used values – actual appreciation on a comparatively new, ‘mainstream’ model – remains a little surprising.
      </Paragraph1>

      <Paragraph1>
        Can the good times continue for the 991 Carrera GTS? Before we can answer that, we need to see what happens when supplies of new and used 992-series 911s are more plentiful. But there’s a chance that by then the Carrera GTS’s cult status might have become a self-perpetuating myth, buoying its values.
      </Paragraph1>

      <Paragraph1>
        So in the short-term prices may rise some more, but probably by a smaller amount. Longer term the 991 Carrera GTS is likely to leverage its critical acclaim to keep values stable and certainly at a much lower percentage level of depreciation than any of its ‘mainstream’ siblings.
      </Paragraph1>

      <Heading4>What is it?</Heading4>

      <Paragraph1>
        The Carrera GTS version of the seventh generation Porsche 911 – widely known as the 991 to identify which iteration of 911 it is – sits at the top of the ‘regular’ range and beneath the more extreme 911 GT3, GT3 RS, Turbo and Turbo S derivatives.
      </Paragraph1>

      <Paragraph1>
        While no Porsche 911 (991) can be considered as anything other than exceedingly fast, the Carrera GTS is the quickest of the non-GT/Turbo models and blends hardcore performance with a very high technical specification and generous equipment levels as standard.
      </Paragraph1>

      <Paragraph1>
        The Carrera GTS is available exclusively in wide-body guise but does offer buyers the choice of coupe, Cabriolet and Targa bodystyles. The Targa is available only with an all-wheel drive transmission, but the rest of the line-up can be found as either rear-wheel drive or all-wheel drive. All models allow you to pick between a seven-speed manual gearbox, or Porsche’s excellent seven-speed PDK dual-clutch automated manual gearbox.
      </Paragraph1>

      <Paragraph1>
        Production of the 991-series 911 ran from 2011 to 2019, but in late 2016 the car underwent a significant facelift – its looks were updated, but of more consequence was the change from a trio of naturally aspirated flat-six 3.4 and 3.8-litre engines, to a bi-turbo 3.0 flat-six in three different states of tune. Pre-facelift models are known as the 991.1 or Gen1, with facelifted cars referred to as the 991.2 or Gen2.
      </Paragraph1>

      <Paragraph1>
        The 316kW 3.8-litre 991 Gen1 Carrera GTS was launched too late in the model cycle to sell in huge numbers, but its critical acclaim ensured that the Gen2 car, with its 331kW bi-turbo 3.0-litre motor, hit the ground running.
      </Paragraph1>


      <Paragraph1>
        The 991 Carrera GTS is the Porsche 911 for enthusiast owners and drivers. It’s regarded by experts as the pinnacle of the ‘regular’ 991 range, blending huge speed with enthralling, engaging handling, yet also adept at everyday situations.
      </Paragraph1>

      <Heading4>What to look out for</Heading4>
      <Paragraph1>
        <strong>Engine:</strong> To date there have been no notable issues to report with either of the GTS’s engines (unlike the 997-series 911). However, there was a recall campaign for some 991s to have their Change Over Valve (COV) solenoids replaced under warranty – ask if this has been done.
      </Paragraph1>

      <Paragraph1>
        It’s worth bearing in mind, though, that Porsche’s recommended service inspection interval for the 991.2 is two years or 30,000km, whichever comes first. Some owners believe this is too long, and therefore get the whole of the car inspected at the same time as the annual oil change to be on the safe side: ask if that is the case with the car you’re looking at.
      </Paragraph1>

      <Paragraph1>
        A pre-purchase inspection by either an official Porsche dealer or a respected Porsche specialist is a worthwhile investment. One benefit is that they should be able to determine from the car’s diagnostics system whether the engine has ever been seriously over-revved – a possibility with a manual – or it is likely the car has been used on track. The latter needn’t be too much of an issue with a Porsche as the company engineers its cars to cope with such use, but there’s a chance that brake discs and pads may be seriously worn.
      </Paragraph1>

      <Paragraph1>
        Associated with the engine but at the front of the car, check the radiators – and air conditioning condensers – positioned low in the GTS’s nose. Because of their location they are prone to suffering from a build-up around them of old leaves and other road debris: in extreme circumstances this can lead to the corrosion of the radiators, requiring their (expensive) replacement.
      </Paragraph1>

      <Paragraph1>
        This accumulation of debris in the 991’s nose can also result in the front duct-mounted cooling fans becoming blocked, burning out their bearings and potentially causing the engine to overheat.
      </Paragraph1>

      <Paragraph1>
        <strong>Gearbox:</strong> The GTS’s seven-speed manual gearbox appears to be problem-free. As yet there are few reported problems with the seven-speed PDK automated manual gearbox, but this gearbox in earlier Porsches and other cars that use the same setup has been the cause of great anguish and huge expense for some owners.
      </Paragraph1>

      <Paragraph1>
        Make sure that the PDK in the car you’re interested in has been regularly inspected, serviced on time, and has been treated to all the latest software updates.
      </Paragraph1>

      <Paragraph1>
        <strong>Suspension:</strong> It’s too early yet for the GTS to start suffering from the degradation of suspension bushes, so if you see uneven tyre wear it could be a sign the suspension geometry has been knocked out of kilter after striking a kerb or pothole.
      </Paragraph1>

      <Paragraph1>
        If the Carrera GTS you’re considering has optional rear-wheel steering (it makes the car more agile at low speed and more stable at high speeds), enquire if it has ever been inspected to ensure it’s operating as it should.
      </Paragraph1>

      <Paragraph1>
        <strong>Cabriolet’s roof:</strong> When you’re looking at a Carrera GTS Cabriolet, check that the roof moves smoothly down and up and fixes securely and easily against the windscreen’s top rail. If it doesn’t, it’s usually just a matter of adjustment.
      </Paragraph1>

      <Paragraph1>
        Ask when the hood’s drain holes were last inspected and cleaned – if the drain holes become blocked it can flood the cabin. At best that means soggy, smelly carpet, while worst case scenario is that the water damages expensive electronic equipment.
      </Paragraph1>

      <Paragraph1>
        <strong>Interior:</strong> A brand new Carrera GTS was sufficiently expensive that if you own one you’re bound to have looked after it. Higher mileage examples might have some scuffing of the driver’s seat’s outer bolster, but there should be little other wear.
      </Paragraph1>

      <Paragraph1>
        The factory options of red stitching and carbon inserts are desirable, and help to ‘lift’ the interior of the car.
      </Paragraph1>

      <Paragraph1>
        Although generally very well built, there are some reports of the 991’s cabin rattling and creaking, particularly around the sunroof. There are a few reports of the heater blower’s resistors going on the blink, while a rare alarm system fault can shut down the whole car.
      </Paragraph1>

      <Paragraph1>
        The Carrera GTS is stuffed with complex electronics and convenience features, so make sure that you try out each and every one to ensure that they’re still functioning. Mending electronics can be time-consuming, frustrating and costly.
      </Paragraph1>

    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage
